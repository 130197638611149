import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Icon,
  IconButton,
  useDisclosure,
  useColorModeValue,
  InputGroup,
  InputLeftElement,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose, AiTwotoneThunderbolt } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdTimeline } from "react-icons/md";
import { BsBook } from "react-icons/bs";
import { FiSun } from "react-icons/fi";
import { IconType } from "react-icons";
import { FaSearch } from "react-icons/fa";
import { FaBell } from "react-icons/fa6";
import { useContext, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import Actions from "./actions/Actions";
import { getNotifications, Action } from "../../../store/actions/homeActions";
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import RTLWrapper from "../../../RTLWrapper";
import { useTranslation } from "react-i18next";
import CartContext from "../../../context/cart/context";
import { getCart } from "../../../store/actions/orderActions";

const dropdownLinks = [
  {
    name: "Projects",
    path: "#",
    icon: MdTimeline,
  },
  {
    name: "Tech Stack",
    path: "#",
    icon: AiTwotoneThunderbolt,
  },
  {
    name: "Open Source",
    path: "#",
    icon: BsBook,
  },
];

interface StateProps {
  notifications: any | null;
  spinner: string | null;
  cart: any | null;
}

interface DispatchProps {
  onGetNotifications: () => void;
  onGetCart: () => void;
}
interface IProps {
  isBlack?: any;
}

const Navbar: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notifications } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    loading,
    cartDetails,

    actions: { getCart },
  } = useContext(CartContext);
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      props.onGetNotifications();
      props.onGetCart();
    }
  }, []);

  const menuProps = {
    bg: useColorModeValue("gray.200", "gray.700"),
    color: useColorModeValue("blue.500", "blue.200"),
  };

  const navLinks = [
    { name: t("home"), path: "/" },
    { name: t("shop"), path: "/shop" },
    {
      name: t("magazine"),
      path: `https://magazine.sadafiq.com/?lang=${i18n.language}`,
    },
    {
      name: t("about"),
      path: `https://about.sadafiq.com/?lang=${i18n.language}`,
    },
  ];
  return (
    <Box
      mx={{ base: "1rem", lg: "20px", xl: "50px" }}
      my={{ base: "0", lg: "28px" }}
      width="100%"
    >
      <Flex
        h={{ base: 14, lg: 10 }}
        alignItems="center"
        justifyContent={{ base: "space-between", lg: "space-between" }}
      >
        <Box padding={0} width={{ lg: "60%", xl: "auto" }}>
          <Flex
            h={{ base: 14, lg: 10 }}
            alignItems="center"
            justifyContent={{ base: "flex-start", lg: "none" }}
            gap={{ sm: "0", lg: 0, xl: "2rem" }}
          >
            <IconButton
              size="sm"
              background="none"
              icon={isOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}
              aria-label="Open Menu"
              display={["none", "none", "none"]}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={{ base: 2, md: 6, lg: 8 }} alignItems="center">
              {isLargeScreen ? (
                <img
                  src={
                    props.isBlack
                      ? "/images/white-logo.png"
                      : "/images/white-logo.png"
                  }
                  // style={{ maxWidth: "2em" }}
                  alt="next"
                  className="h-[40px] cursor-pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              ) : (
                <img
                  className="h-[30px] cursor-pointer"
                  src={
                    props.isBlack
                      ? "/images/white-logo.png"
                      : "/images/white-logo.png"
                  }
                  alt="Sadaf logo"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              )}
              <HStack
                as="nav"
                justifyContent={"space-between"}
                display={{ base: "none", md: "none", lg: "flex" }}
                alignItems="center"
                gap={{ lg: "0", xl: "0.5rem" }}
              >
                {navLinks.map((link, index) => (
                  <NavLink
                    key={index}
                    {...link}
                    onClose={onClose}
                    isBlack={props.isBlack}
                  />
                ))}
              </HStack>
            </HStack>
          </Flex>
        </Box>
        <Actions
          notifications={notifications}
          cartDetails={props.cart}
          isBlack={props.isBlack}
        />
      </Flex>

      {/* Mobile Screen Links */}
      {isOpen ? (
        <Box pb={4} bgColor={"white"} display={["inherit", "inherit", "none"]}>
          <Stack as="nav" spacing={2}>
            {navLinks.map((link, index) => (
              <NavLink
                key={index}
                {...link}
                onClose={onClose}
                isBlack={props.isBlack}
              />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

// NavLink Component
interface NavLinkProps {
  name: string;
  path: string;
  onClose: () => void;
  isBlack: any;
}

const NavLink = ({ name, path, onClose, isBlack }: NavLinkProps) => {
  const link = {
    bg: useColorModeValue("gray.200", "gray.700"),
    color: useColorModeValue("blue.500", "blue.200"),
  };
  const { pathname } = useLocation();
  return (
    <Link to={path}>
      <Box
        px={{ base: 1, md: 3, lg: 3 }}
        py={{ base: 1, md: 1, lg: 1 }}
        lineHeight="inherit"
        rounded="md"
        color={pathname === path ? "#fff" : isBlack ? "#959595" : "#fff"}
        textDecoration={pathname === path ? "underline" : "none"}
        _hover={{
          textDecoration: "underline",
          // bg: link.bg,
          color: "#959595",
        }}
        fontSize={{ base: "8px", md: "10px", lg: "10px", xl: "14px" }}
        onClick={() => onClose()}
        textTransform={"uppercase"}
      >
        {name}
      </Box>
    </Link>
  );
};

// Dropdown MenuLink Component
interface MenuLinkProps {
  name: string;
  path: string;
  icon: IconType;
  onClose: () => void;
}

const MenuLink = ({ name, path, icon, onClose }: MenuLinkProps) => {
  return (
    <Link to={path} onClick={() => onClose()}>
      <MenuItem
        _hover={{
          color: "blue.400",
          bg: useColorModeValue("gray.200", "gray.700"),
        }}
      >
        <HStack>
          <Icon as={icon} size={18} color="blue.400" />
          <Text>{name}</Text>
        </HStack>
      </MenuItem>
    </Link>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    notifications: state.home.notifications,
    spinner: state.home.spinner,
    cart: state.orders.cart,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetNotifications: () => dispatch(getNotifications()),
  onGetCart: () => dispatch(getCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
