import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";

type Props = {
  popNotification: {
    id: number;
    image: string;
    body: string;
    title: string;
  };
};

const PopNotification = ({ popNotification }: Props) => {
  const [showNotification, setShowNotification] = useState<boolean>(true);

if (!popNotification?.image) return null 
  return (
    <Modal
      isOpen={showNotification}
      onClose={() => setShowNotification(false)}
      isCentered
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent borderRadius={"10px"}>
        {/* <ModalHeader>{popNotification.title}</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody p={0} borderRadius={"10px"}>
          <Image
            src={popNotification.image}
            alt={popNotification.title}
            w={"100%"}
            borderRadius={"10px"}
            maxH={"90vh"}
          />
          {/* <Text>{popNotification.body}</Text> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PopNotification;
