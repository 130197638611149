import {
  Center,
  Flex,
  IconButton,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import MessageCart from "../../Components/MessageCart";
import {
  getConversation,
  getConversations,
  Action,
  getMessages,
  sendMessage,
  getOrderMessages,
  sendOrderMessage,
} from "../../../../store/actions/messageActions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import { RootState } from "../../../../store/reducers/rootReducer";
import { useTranslation } from "react-i18next";

interface StateProps {
  spinner: string | null;
  messages: any | null;
}

interface DispatchProps {
  onGetMessages: () => void;
  onSendMessage: (formData: any) => void;
}

interface IProps {
  setTicketId?: (ticketId: number) => void;
}
const SupportMessages: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const { t } = useTranslation();
  const [selectedMessage, setSelectedMessage] = useState<any | null>(null);
  const [formData, setFormData] = useState<{
    message: string;
    conversation_id: null | number;
    file?: File | null;
  }>({
    message: "",
    conversation_id: null,
    file: null,
  });
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [props.messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };
  // Generic handler for input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    if (fieldName === "file") {
      // Cast e.target to HTMLInputElement
      const inputElement = e.target as HTMLInputElement;
      const selectedFile = inputElement.files?.[0];

      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedFile || null,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: e.target.value,
      }));
    }
  };

  // Event handler for the Save Changes button
  const handleSend = () => {
    if (!formData.file) delete formData.file;

    props.onSendMessage(formData);
    setFormData((prevData) => ({
      ...prevData,
      message: "",
      file: null,
    }));
  };

  useEffect(() => {
    props.onGetMessages();
  }, []);

  useEffect(() => {
    if (!formData.conversation_id && props.messages)
      setFormData((prevData) => ({
        ...prevData,
        conversation_id: props.messages
          ? props.messages.length > 0
            ? props.messages[0].conversation_id
            : null
          : null,
      }));
    scrollToBottom();
    if (props.setTicketId)
      props.setTicketId(
        props.messages && props.messages.length > 0
          ? props.messages[0].ticket_uuid
          : 0
      );
  }, [props.messages]);
  //   if (props.messages)
  return (
    <>
      {/* <ModalOverlay />{" "}
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Text>{t("support")}</Text>
          </Flex>
        </ModalHeader> */}
      {/* <ModalCloseButton />
        <ModalBody style={{ minHeight: "375", overflowY: "auto" }}> */}
      {/* Chat Messages */}
      {props.messages && props.messages.length > 0 ? (
        <div
          className="flex-1 p-2"
          style={{ overflowY: "auto", height: "415px", maxHeight: "415px" }}
          ref={messagesEndRef}
        >
          {" "}
          {/* Apply overflow-y: auto */}
          {props.messages.map((message: any) => (
            <div
              key={message.id}
              className={`flex w-full ${
                message.sender_type !== "user" ? "justify-start" : "justify-end"
              }`}
            >
              <div className="py-4 ">
                <MessageCart
                  isLeft={message.sender_type === "user" ? false : true}
                  image={
                    message.sender_type === "user"
                      ? message.user.avatar
                      : message.admin.avatar
                  }
                  text={message.body}
                  isAudio={false}
                  id={message.id}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Center height="403px">
          {props.spinner === "loading" ? (
            <Spinner size="xl" />
          ) : (
            <Text> {t("chatEmpty")}</Text>
          )}
        </Center>
      )}

      {/* Input Div */}
      <div className="border-t border-[#F2F2F2] flex">
        <Input
          placeholder={t("typeMessage")}
          border="none"
          borderBottomColor="white"
          _placeholder={{
            textSizeAdjust: "4px",
            fontSize: "16px",
          }}
          _focus={{
            border: "none",
            boxShadow: "none",
            borderBottomColor: "white",
          }}
          value={formData.message}
          onChange={(e) => handleInputChange(e, "message")}
        />
        {formData.file && (
          <div
            style={{
              marginInlineStart: "8px",
              fontSize: "14px",
              color: "#4CAF50",
            }}
          >
            {t("fileUploaded")}
          </div>
        )}
        <div className="flex">
          <IconButton
            colorScheme="white"
            borderRadius="100px"
            aria-label="Send message"
            icon={
              <img
                src="/images/support/sendMessageIcon.png"
                className="h-[30px] w-[30px] cursor-pointer"
                alt=""
              />
            }
            onClick={() => handleSend()}
          />
          <IconButton
            mx="8px"
            colorScheme="white"
            borderRadius="100px"
            aria-label="Record audio"
            icon={
              <label htmlFor="file-upload">
                <img
                  className="h-[30px] w-[30px] cursor-pointer"
                  src="/images/support/upload.png"
                  alt=""
                />
              </label>
            }
          />
          <input
            type="file"
            id="file-upload"
            style={{ display: "none" }}
            onChange={(e) => handleInputChange(e, "file")}
          />
        </div>
      </div>
      {/* </ModalFooter>
      </ModalContent> */}
    </>
  );
  //   else return null;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.messages.spinner,
    messages: state.messages.messages,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetMessages: () => dispatch(getMessages()),
  onSendMessage: (formData: any) => dispatch(sendMessage(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportMessages);
