import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { pageView } from "../../../config/FirebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { getPages } from "../../../store/actions/homeActions";

const Terms = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pages = useSelector((state: any) => state.home.pages);
  const [termsPageContent, setTermsPageContent] = useState("");

  useEffect(() => {
    pageView(window.location.pathname);
    dispatch(getPages());
  }, []);

  useEffect(() => {
    if (pages) {
      const temp = pages.find((page: any) => page.slug == "Terms_Conditions");
      setTermsPageContent(temp.body);
    }
  }, [pages]);

  return (
    <Box
      style={{ backgroundColor: "#FFFFFF" }}
      p={{ base: "10px", md: "10px", lg: "37px" }}
    >
      <Text dangerouslySetInnerHTML={{ __html: termsPageContent }} />
    </Box>
  );
};

export default Terms;
