import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  questions?: any | null;
  spinner?: string | null;
  selectedParentQuestion?: number;
  handleOpenChat: (id: number) => void;
  handleRadioChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}
const ChildQuestions = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Box px="30px" mt="30px">
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Text fontSize={"12px"} fontWeight={"regular"} color={"#7B7B7B"}>
          {t("chooseSection")}
        </Text>
      </Flex>
      {/* <RadioGroup mt="33.5px"> */}
      <Stack mt="33.5px" spacing={4} height={"207px"} maxHeight={"207px"}>
        {props.questions &&
          props.questions.map((question: any) => {
            return (
              <Radio
                value={question.id}
                isChecked={props.selectedParentQuestion == question.id}
                onChange={(e) => {
                  props.handleRadioChange(e);
                }}
              >
                {question.question}
              </Radio>
            );
          })}
      </Stack>
      <Button
        backgroundColor={"black"}
        color={"white"}
        borderRadius={"0px"}
        width={{ base: "100%", md: "100%", lg: "100%x" }}
        // className="mt-[30px] lg:w-[100%] md:w-[100%] sm:w-[100%] m-3"
        _hover={{ bgColor: "#333131" }}
        isDisabled={!props.selectedParentQuestion}
        isLoading={props.spinner === "loading" ? true : false}
        onClick={() =>
          props.handleOpenChat(props?.questions[props.questions.length - 1]?.id)
        }
      >
        {t("accept")}
      </Button>
      {/* </RadioGroup> */}
    </Box>
  );
};

export default ChildQuestions;
