import SearchAutoComplete from "./SearchAutoComplete";
import { Box, Flex } from "@chakra-ui/react";
import ComparableProduct from "./ComparableProduct";
import { useParamsId } from "../../utils/hooks/useParamsId";
import { useState } from "react";

type Props = {};

const CompareProducts = (props: Props) => {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const id = useParamsId();

  return (
    <Box
      mt={{ base: "6em", md: "6em", lg: "9.375em" }}
      px={{ base: "1rem", md: "none", lg: "120px" }}
    >
      <SearchAutoComplete setSelectedProduct={setSelectedProduct} />
      {selectedProduct && (
        <Flex
          flexDirection={{ base: "column", md: "row", lg: "row" }}
          alignItems="center"
          minHeight="100%" // Set the minimum height of Flex to 100%
        >
          <Box width={{ base: "100%", md: "50%", lg: "50%" }} flex="1">
            <ComparableProduct id={+id!} />
          </Box>

          {/* Custom Divider */}
          <Box
            my={{ base: "4", md: "0" }}
            mx={{ base: "0", md: "4" }}
            height={{ base: "2px", md: "auto" }}
            width={{ base: "100%", md: "3px" }}
            bg="black"
            alignSelf="stretch" // Force the divider to stretch to full height
          />

          <Box width={{ base: "100%", md: "50%", lg: "50%" }} flex="1">
            <ComparableProduct id={selectedProduct} anotherProduct={true} />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CompareProducts;
