import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../store/reducers/rootReducer";
import {
  Action,
  getChatSettings,
  getQuestions,
} from "../../../store/actions/userActions";
import FAQCard from "../Components/FAQCard";
import { useTranslation } from "react-i18next";
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import SupportMessages from "../Components/messages/SupportMessages";
import StepperSupportMessages from "../Components/messages/stepper-support-messages/StepperSupportMessages";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  faQuestions: any | null;
  chatSettings: {
    work_begins: string;
    work_ends: string;
    auto_message: string;
  } | null;
  loadingChatSettings: string | null;
}

interface DispatchProps {
  onGetQuestions: () => void;
  onGetChatSettings: () => void;
}

interface Question {
  id: number;
  title: string;
  body: string;
}

interface IProps {}

const FAQ: React.FC<StateProps & DispatchProps & IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    isOpen: isChatModalOpen,
    onOpen: onChatModalOpen,
    onClose: onChatModalClose,
  } = useDisclosure();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    pageView(window.location.pathname);
    props.onGetQuestions();
    props.onGetChatSettings();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onChatModalClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onChatModalClose]);

  console.log("chatSettings", props.chatSettings);

  if (props.loadingChatSettings === "loading") {
    return (
      <Center width={"100%"}>
        <Spinner size="xl" />
      </Center>
    );
  }
  return (
    <Box p={{ base: "10px", md: "10px", lg: "37px" }} bgColor={"#FFFFFF"}>
      {!isChatModalOpen ? (
        <Box
          position="fixed"
          bottom="4rem"
          right="2rem"
          zIndex="10000"
          p={{ base: "0.5rem", md: "0.5rem", lg: "1rem" }}
          bg="white"
          borderRadius="md"
          boxShadow="lg"
          cursor={"pointer"}
          onClick={onChatModalOpen}
        >
          <Flex>
            <img
              src="/images/support/support-icon.png"
              className="lg:h-[30px] lg:w-[30px] xs:h-[15px] xs:h-[15px]"
            />
            <Text
              marginInlineStart={"5px"}
              style={{
                color: "#171717",
                fontWeight: "bold",
              }}
              fontSize={{ base: "0.7rem", md: "0.7rem", lg: "0.875rem" }}
            >
              {t("doYouNeedHelp")}
            </Text>
          </Flex>
        </Box>
      ) : (
        <Box
          ref={modalRef}
          position={{ base: "fixed", md: "relative", lg: "fixed" }} // Adjust position based on screen size
          top={{ base: "50%", md: "auto", lg: "none" }} // Adjust top position based on screen size
          left={{ base: "50%", md: "auto", lg: "none" }} // Adjust left position based on screen size
          transform={{ base: "translate(-50%, -50%)", md: "none", lg: "none" }} // Adjust transform based on screen size
          bottom={{ lg: "4rem" }}
          right={{ lg: "2rem" }}
          bg="white"
          height={"480px"}
          maxHeight={"480px"}
          width={"371px"}
          borderRadius="md"
          boxShadow="lg"
          cursor={"pointer"}
          onClick={onChatModalOpen}
        >
          <StepperSupportMessages />
        </Box>
      )}
      <Box className="mb-[40px]" dir={i18n.dir()} textAlign={"start"}>
        <Text
          className="mb-[10px]"
          fontSize={{ base: "12px", md: "12px", lg: "24px" }}
        >
          {props.chatSettings?.auto_message}
        </Text>
        <Flex alignItems={"center"} gap={"40px"}>
          <Text>
            {t("start_of_work")} / {props.chatSettings?.work_begins}
          </Text>
          <Text>
            {t("end_of_work")} / {props.chatSettings?.work_ends}
          </Text>
        </Flex>
      </Box>
      {props.faQuestions &&
        props.faQuestions.map((question: Question) => {
          return (
            <FAQCard
              // number={question.id}
              question={question.title}
              answer={question.body}
            />
          );
        })}
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        padding={{ base: 5, md: 5, lg: 10 }}
        mt={{ base: "1.5em", md: "1.5em", lg: "5em" }}
      >
        <Grid
          templateColumns={{ base: "1fr ", md: "1fr" }}
          gap={{ base: 4, md: 8 }}
        >
          <GridItem
            colSpan={1}
            justifyContent={{ base: "center" }}
            alignItems={{ base: "center" }}
          >
            <Flex flexDirection={"column"}>
              <Text fontSize={{ base: "1.4rem", md: "1.4rem", lg: "2.8rem" }}>
                {t("needHelp")}
              </Text>
              <Text
                mt="2em"
                style={{
                  color: "#4E5B72",
                }}
                fontSize={{ base: "1rem", md: "1rem", lg: "1rem" }}
              >
                {t("blueButton")}
                <a href="mailto:info@sadafiq.com" style={{ color: "#0092FF" }}>
                  info@sadafiq.com
                </a>
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    faQuestions: state.user.faQuestions,
    chatSettings: state.user.chatSettings,
    loadingChatSettings: state.user.spinner,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetQuestions: () => dispatch(getQuestions()),
  onGetChatSettings: () => dispatch(getChatSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
