import React, { useEffect, useState } from "react";
import {
  Grid,
  GridItem,
  Flex,
  Text,
  Button,
  Box,
  useBreakpointValue,
  Drawer,
  Checkbox,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import CategoriesContext from "../../context/categories/context";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { CiFilter } from "react-icons/ci";
import { FaFilter } from "react-icons/fa6";

type Props = {
  params: {
    from_price: number;
    to_price: number;
    search: undefined | string;
    category_id: undefined | number;
    per_page: undefined | number;
    subCategory_id: undefined | number;
  };
};

const ShopCategoryFilter = ({ params }: Props) => {
  const isLargeScreen = useBreakpointValue({
    base: false,
    md: false,
    lg: true,
  });
  const { t } = useTranslation();
  const { list: categoryList } = useContext(CategoriesContext);
  const {
    subCategoriesList,
    loading,
    actions: { getSubCategories },
  } = useContext(CategoriesContext);

  const [hoveredCategory, setHoveredCategory] = useState<number | null>(null);

  useEffect(() => {
    if (hoveredCategory) {
      getSubCategories(hoveredCategory!);
    }
  }, [hoveredCategory]);

  useEffect(() => {
    if (!isLargeScreen && params.category_id)
      getSubCategories(params.category_id!);
  }, [params.category_id]);

  //state for drawer
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return isLargeScreen ? (
    <Box
      width={{ base: "100%", md: "100%" }}
      justifyItems={"start"}
      px={{ base: "0.5rem", md: "0.5rem", lg: "30px" }}
      className="overflow-x-scroll scroll"
      overflow={"visible"}
      padding={{ base: "0.5rem", md: "0.5rem", lg: "30px" }}
      marginBottom={{ base: "0", md: "0", lg: "5rem" }}
    >
      {categoryList?.data && (
        <Box mb={1} background={"none"}>
          <Flex
            justifyContent={"center"}
            alignItems={"flex-start"}
            flexDirection={"row"}
            gap={3}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            {categoryList?.data.map((category: any) => {
              return (
                <Box position={"relative"}>
                  <div className="rounded-lg" key={category.id}>
                    <div
                      onClick={async () => {
                        navigate(
                          {
                            pathname: "/shop",
                            search: `?c=${category.id}`,
                          },
                          { replace: true }
                        );
                      }}
                      id={`category-${category.id}`}
                      onMouseEnter={() => setHoveredCategory(category.id)}
                      className="relative rounded-lg flex justify-center cursor-pointer lg:h-[200px] lg:w-[200px] xs:h-[120px] xs:w-[120px]"
                    >
                      {category?.image && (
                        <img
                          alt="category photo"
                          className="bg-cover w-full rounded-lg"
                          src={category?.image}
                        />
                      )}

                      {/* <div className="h-[270px]  w-[270px] top-0  absolute opacity-50 bg-black hover:opacity-30 transition-opacity "></div> */}

                      <div
                        className={`absolute rounded-lg font-bold  text-center z-2 lg:text-[16px] xs:text-[10px] ${
                          category?.image
                            ? "bg-black bg-opacity-50 hover:bg-opacity-30 ease-in-out transition text-white "
                            : "text-black shadow-md"
                        }  w-full h-full `}
                      >
                        <VStack
                          className="relative lg:top-[70px] xs:top-[35px]"
                          direction={"column"}
                        >
                          <img
                            className="block lg:w-[50px] lg:h-[50px] xs:h-[30px] xs:w-[30px]"
                            alt="bathroom"
                            src={category?.icon}
                          />
                          <Text className="block">{category?.name}</Text>
                        </VStack>
                      </div>
                    </div>
                  </div>

                  {hoveredCategory == category.id &&
                    subCategoriesList?.data &&
                    subCategoriesList?.data.length > 0 &&
                    !loading[0] && (
                      <>
                        <Box
                          p={5}
                          w={"300px"}
                          id="sub-category"
                          borderWidth="1px"
                          borderRadius="md"
                          boxShadow="md"
                        >
                          <Grid
                            templateColumns={["1fr"]}
                            gap={3}
                            justifyItems={"center"}
                          >
                            {subCategoriesList?.data.map((subCategory: any) => {
                              return (
                                <GridItem
                                  key={subCategory.id}
                                  w={"90%"}
                                  fontSize={"12px"}
                                  onClick={async () => {
                                    navigate(
                                      {
                                        pathname: "/shop",
                                        search: `?s=${subCategory.id}`,
                                      },
                                      { replace: true }
                                    );
                                  }}
                                  cursor={"pointer"}
                                  color={"#000"}
                                  pb={1}
                                  transition={"all 0.2s linear"}
                                  _hover={{
                                    borderBottom: "1px solid #000",
                                    fontWeight: 700,
                                  }}
                                >
                                  <Text whiteSpace={"nowrap"}>
                                    {subCategory.name}
                                  </Text>
                                </GridItem>
                              );
                            })}
                          </Grid>
                        </Box>
                      </>
                    )}
                </Box>
              );
            })}
          </Flex>
        </Box>
      )}
    </Box>
  ) : (
    <>
      <Flex
        width={"100%"}
        justifyContent={"space-between"}
        padding={"20px 30px"}
        alignItems={"center"}
      >
        {/* <IconButton
          w={"90%"}
          background={"black"}
          color={"white"}
          _hover={{ bg: "#171717" }}
          onClick={() => setIsOpen(true)}
        > */}
        <Text>{t("category")}</Text>
        <Flex
          gap={"10px"}
          alignItems={"center"}
          justifyContent={"center"}
          onClick={() => setIsOpen(true)}
          cursor={"pointer"}
        >
          <FaFilter style={{ fontWeight: 800, fontSize: "20px" }} />
          <Text fontWeight={600}>{t("filters")}</Text>
        </Flex>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DrawerOverlay zIndex={999} />
        <DrawerCloseButton />
        <DrawerContent p={5}>
          <Grid
            templateColumns={["1fr"]}
            width={{ base: "100%", md: "27%" }}
            background={"#F9F9F9"}
          >
            {categoryList?.data && (
              <GridItem mb={1}>
                <Text
                  fontSize={{ base: "16px", md: "16px", lg: "20px" }}
                  fontWeight={700}
                  whiteSpace={"nowrap"}
                  mb={2}
                >
                  {t("category")}
                </Text>
                <Flex
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  {categoryList?.data?.map((category: any) => {
                    return (
                      <Checkbox
                        key={category.id}
                        value={category.id}
                        isChecked={params.category_id == category.id}
                        onChange={(e) => {
                          navigate(
                            {
                              pathname: "/shop",
                              search: `?c=${e.target.value}`,
                            },
                            { replace: true }
                          );
                        }}
                        iconColor="black"
                        colorScheme="black"
                      >
                        {category.name}
                      </Checkbox>
                    );
                  })}
                </Flex>
              </GridItem>
            )}
            {params.category_id &&
              subCategoriesList?.data &&
              subCategoriesList?.data.length > 0 && (
                <GridItem mb={1}>
                  <Text
                    fontSize={{ base: "16px", md: "16px", lg: "20px" }}
                    fontWeight={700}
                    whiteSpace={"nowrap"}
                    mb={2}
                    mt={1}
                  >
                    {t("subcategory")}
                  </Text>
                  <Flex
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                  >
                    {subCategoriesList?.data?.map((subCategory: any) => {
                      return (
                        <Checkbox
                          key={subCategory.id}
                          value={subCategory.id}
                          isChecked={params.subCategory_id == subCategory.id}
                          onChange={(e) =>
                            navigate(
                              {
                                pathname: "/shop",
                                search: `?s=${e.target.value}`,
                              },
                              { replace: true }
                            )
                          }
                          iconColor="black"
                          colorScheme="black"
                        >
                          {subCategory.name}
                        </Checkbox>
                      );
                    })}
                  </Flex>
                </GridItem>
              )}
          </Grid>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ShopCategoryFilter;
