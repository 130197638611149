import { Box, Center, Flex, Spinner, useSteps } from "@chakra-ui/react";
import "./Stepper.css";
import Checkout from "./Steps/Checkout";
import ConfirmAddress from "./Steps/ConfirmAddress";
import RequestIsDone from "./Steps/RequestIsDone";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import StepperTest from "../../components/user/order-stepper/OrderStepper";
import { useTranslation } from "react-i18next";
import BreadCrumbComponent from "../../components/bread-crumb/BreadCrumbComponent";
import { useDispatch, useSelector } from "react-redux";
import { orderPaymentCallback } from "../../store/actions/orderActions";

interface IProps {
  index: number;
}
const ShoppingCart = (props: IProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("cash");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [orderDone, setOrderDone] = useState(false);
  const orderisLoading = useSelector((state: any) => state.orders.spinner);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  const status = searchParams.get("status");

  const steps = [
    {
      title: t("checkout"),
      description: t("firstStep"),
      component: (
        <Checkout
          onNext={() => setActiveStep(activeStep + 1)}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
      ),
    },
    {
      title: t("confirmAddress"),
      description: t("secondStep"),
      component: (
        <ConfirmAddress
          onNext={() => setActiveStep(activeStep + 1)}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      ),
    },
    {
      title: t("requestIsDone"),
      description: t("thirdStep"),
      component: (
        <RequestIsDone status={status!} isError={orderisLoading === "error"} />
      ),
    },
  ];

  useEffect(() => {
    if (status == "success" && order_id && !orderDone)
      dispatch(orderPaymentCallback(order_id), () => {
        setOrderDone(true);
      });
  }, [order_id, status]);

  const { activeStep, setActiveStep } = useSteps({
    index: props.index ? props.index : 0,
    count: steps.length,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (orderisLoading === "loading" && props.index == 2)
    return (
      <Center width={"100%"} h={"100vh"}>
        <Spinner size="xl" />
      </Center>
    );
  return (
    <Box
      px={{ base: "1rem", md: "none", lg: "9rem" }}
      mt={{ base: "4em", md: "4em", lg: "9.375em" }}
    >
      <BreadCrumbComponent />
      <Flex flexDirection="column" mt={{ base: "1em", md: "1em", lg: "3em" }}>
        <StepperTest steps={steps} activeStep={activeStep} />
        <Box mt="2em" display={""}>
          {steps[activeStep].component}
        </Box>
      </Flex>
    </Box>
  );
};

export default ShoppingCart;
