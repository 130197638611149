import React, { useEffect, useState } from "react";
import { RootState } from "../../../../../store/reducers/rootReducer";
import {
  Action,
  CheckTicketsStatus,
  CreateNewTicket,
  getChildQuestions,
  getParentQuestions,
} from "../../../../../store/actions/messageActions";
import { ThunkDispatch } from "redux-thunk";
import { Box, Center, Divider, Flex, Spinner, Text } from "@chakra-ui/react";
import ParentQuestions from "../ParentQuestions";
import { connect } from "react-redux";
import ChildQuestions from "../ChildQuestions";
import SupportMessages from "../SupportMessages";

interface StateProps {
  questions?: any | null;
  spinner?: string | null;
  ticketsStatus?: any | null;
}

interface DispatchProps {
  onGetParentQuestions?: () => void;
  onGetChildQuestions: (parentId: number) => void;
  onCheckTicketsStatus?: () => void;
  onCreateTicket: (id: number) => void;
}

interface IProps {}

const StepperSupportMessages: React.FC<StateProps & DispatchProps & IProps> = (
  props
) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedParentQuestion, setSelectedParentQuestion] =
    useState<number>();

  const [ticketId, setTicketId] = useState<number>(0);

  const handleOpenChat = (questionId: number) => {
    props.onCreateTicket(questionId);
    setSelectedParentQuestion(undefined);
    setActiveStep(2);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedParentQuestion(parseInt(event.target.value));
  };

  const handleGetChildQuestions = () => {
    if (selectedParentQuestion) {
      props.onGetChildQuestions(selectedParentQuestion);
      setActiveStep(1);
      setSelectedParentQuestion(undefined);
    }
  };

  useEffect(() => {
    if (props.onGetParentQuestions) props.onGetParentQuestions();
    if (props.onCheckTicketsStatus) {
      props.onCheckTicketsStatus();
    }
  }, []);

  useEffect(() => {
    if (props.ticketsStatus?.hasOpenTicket == "true") {
      setActiveStep(2);
    }
  }, [props.ticketsStatus]);

  return (
    <Box>
      <Flex justifyContent={"center"} alignItems={"center"}>
        <Text fontSize={"16px"} color="#171717" fontWeight={"bold"}>
          {`Sadaf ${ticketId != 0 ? `#${ticketId}` : ""}`}
        </Text>
      </Flex>
      <Divider />
      <Box>
        {activeStep === 0 ? (
          <ParentQuestions
            questions={props.questions}
            spinner={props.spinner}
            selectedParentQuestion={selectedParentQuestion}
            handleRadioChange={handleRadioChange}
            handleGetChildQuestions={handleGetChildQuestions}
          />
        ) : null}

        {activeStep === 1 ? (
          <ChildQuestions
            questions={props.questions}
            spinner={props.spinner}
            selectedParentQuestion={selectedParentQuestion}
            handleRadioChange={handleRadioChange}
            handleOpenChat={handleOpenChat}
          />
        ) : null}
        {activeStep === 2 ? (
          <SupportMessages setTicketId={setTicketId} />
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    questions: state.messages.questions,
    spinner: state.messages.spinner,
    ticketsStatus: state.messages.ticketsStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetParentQuestions: () => dispatch(getParentQuestions()),
  onGetChildQuestions: (parentId: number) =>
    dispatch(getChildQuestions(parentId)),
  onCheckTicketsStatus: () => dispatch(CheckTicketsStatus()),
  onCreateTicket: (id: number) => {
    dispatch(CreateNewTicket(id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepperSupportMessages);
