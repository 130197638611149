// userReducer.ts
export interface MainState {
  governors: any | null;
  profile: any | null;
  spinner: string | null;
  questions: any | null;
  faQuestions: any | null;
  err: any | null;
  chatSettings: any | null;
}

interface Action {
  type: string;
  res?: any;
}

const initState: MainState = {
  governors: null,
  profile: null,
  spinner: null,
  questions: null,
  faQuestions: null,
  err: null,
  chatSettings: null,
};

const userReducer = (
  state: MainState = initState,
  action: Action
): MainState => {
  switch (action.type) {
    case "GET_GOVERNORS_SUCCESS":
      return {
        ...state,
        spinner: null,
        governors: action.res.data.data,
      };
    case "GET_GOVERNORS_ERROR":
      return {
        ...state,
      };
    case "GET_GOVERNORS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_PROFILE_SUCCESS":
      return {
        ...state,
        spinner: null,
        profile: action.res.data.data,
      };
    case "GET_PROFILE_ERROR":
      return {
        ...state,
      };
    case "GET_PROFILE_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "UPDATE_PROFILE_SUCCESS":
      return {
        ...state,
        spinner: "success",
        err: null,
        profile: action.res.data.data,
      };
    case "UPDATE_PROFILE_ERROR":
      return {
        ...state,
        spinner: null,
        err: action?.res?.data?.errors[0] ?? "error",
      };
    case "UPDATE_PROFILE_LOADING":
      return {
        ...state,
        spinner: "loading",
        err: null,
      };

    case "GET_FAQ_QUESTIONS_SUCCESS":
      return {
        ...state,
        spinner: null,
        faQuestions: action.res.data.data,
      };
    case "GET_FAQ_QUESTIONS_ERROR":
      return {
        ...state,
      };
    case "GET_FAQ_QUESTIONS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_CHAT_SETTINGS_SUCCESS":
      return {
        ...state,
        spinner: null,
        chatSettings: action.res.data,
      };
    case "GET_CHAT_SETTINGS_ERROR":
      return {
        ...state,
        spinner: null,
        err: action?.res?.data?.errors[0] ?? "error",
      };
    case "GET_CHAT_SETTINGS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    default:
      return state;
  }
};

export default userReducer;
