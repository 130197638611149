import React, { useContext, useEffect, useState } from "react";
import CheckoutCart from "./Components/CheckoutCart";
import {
  InputGroup,
  Input,
  Text,
  useDisclosure,
  Modal,
  Center,
  Spinner,
  Flex,
  Card,
  CardBody,
  Stack,
  Button,
  Box,
  Divider,
  Heading,
  CardFooter,
  Radio,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import CartContext from "../../../context/cart/context";
import ConfirmationDialog from "./Components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import {
  Action,
  addCouponCode,
  decreaseProductCart,
  deleteProductFromCart,
  getCart,
  increaseProductCart,
  removeCouponCode,
} from "../../../store/actions/orderActions";
import { RootState } from "../../../store/reducers/rootReducer";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { pageView } from "../../../config/FirebaseConfig";

interface StateProps {
  spinner: string | null;
  cartSpinner: string | null;
  cart: any | null;
}

interface DispatchProps {
  onGetCart: () => void;
  onDeleteProductFromCart: (productId: number | undefined) => void;
  onIncreaseProductCart: (productId: number | undefined) => void;
  onDecreaseProductCart: (productId: number | undefined) => void;
  onAddCouponCode: (code: string) => void;
  onRemoveCouponCode: () => void;
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const outline = definePartsStyle({
  control: {
    border: "1px solid",
    borderColor: "gray.300",

    // Let's also provide dark mode alternatives
    _dark: {
      borderColor: "gray.600",
    },
  },
});
interface IProps {
  cart: any | null;
  cartSpinner: string | null;
  onNext: () => void;
  selectedPaymentMethod: string;
  setSelectedPaymentMethod: (value: string) => void;
  onGetCart: () => void;
  onDeleteProductFromCart: (productId: number | undefined) => void;
  onIncreaseProductCart: (productId: number | undefined) => void;
  onDecreaseProductCart: (productId: number | undefined) => void;
  onAddCouponCode: (code: string) => void;
  onRemoveCouponCode: () => void;
}

const textStyle = {
  color: "#171717",
};

const smallPrice = {
  fontSize: "10px",
  fontWeight: "bold",
};
const Checkout: React.FC<StateProps & DispatchProps & IProps> = ({
  onNext,
  cart,
  cartSpinner,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  onDeleteProductFromCart,
  onIncreaseProductCart,
  onDecreaseProductCart,
  onAddCouponCode,
  onRemoveCouponCode,
  onGetCart,
}: IProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [couponCode, setCouponCode] = useState<string>("");
  const {
    loading,
    cartDetails,

    actions: {
      getCart,
      decreaseProductCart,
      increaseProductCart,
      deleteProductCart,
      addCouponToCart,
      removeCouponFromCart,
    },
  } = useContext(CartContext);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(event.target.value);
  };

  useEffect(() => {
    pageView(window.location.pathname);
    if (cartDetails?.data?.coupon)
      setCouponCode(cartDetails?.data?.coupon?.code);
    onGetCart();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [cart]);

  const spinnerLoading = () => {
    return (
      <Grid templateColumns={["1fr"]} width="100%">
        <Center height="100vh" width={"100%"}>
          <Spinner size="xl" />
        </Center>
      </Grid>
    );
  };

  const emptyCart = () => {
    return (
      <Grid templateColumns={["1fr"]} width="100%" mt="50px">
        <Center width={"100%"}>
          <img src="/images/empty/empty-cart.png" />
        </Center>{" "}
        <Flex justifyContent={"center"} alignItems={"center"} mt="20px">
          <Text fontSize={"16px"} fontWeight={"bold"}>
            {t("emptyCart")}
          </Text>
        </Flex>
      </Grid>
    );
  };
  if (cartSpinner && cartSpinner === "loading") {
    // Display a spinner while loading
    return spinnerLoading();
  }
  if (cart)
    if (cart.products)
      if (cart.products.length > 0)
        return (
          <Box>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={{ base: "xs", md: "sm", lg: "md" }}
            >
              <ConfirmationDialog
                onClose={onClose}
                onClick={onNext}
                spinner={null}
              />
            </Modal>
            {/* first bart */}
            <Grid
              templateColumns={["1fr", "1fr 1fr"]}
              gap={{ base: 0, md: 0, lg: 10 }}
            >
              <GridItem colSpan={1}>
                {/* <div> */}
                <Text
                  fontSize={{ base: "12px", md: "12px", lg: "16px" }}
                  fontWeight={"bold"}
                >
                  {t("cart")}
                </Text>{" "}
                <div>
                  {cart?.products?.map((p: any) => {
                    return (
                      <CheckoutCart
                        key={p.id}
                        categoryName={"kitchen"}
                        color={"red"}
                        image={p?.thumbnail}
                        quantity={p?.quantity}
                        price={p?.discount_price}
                        subPrice={p?.price}
                        typeName={p?.name}
                        decreaseProductCart={() => onDecreaseProductCart(p?.id)}
                        increaseProductCart={() => onIncreaseProductCart(p?.id)}
                        deleteProductCart={() => onDeleteProductFromCart(p?.id)}
                      />
                    );
                  })}
                  {/* </div> */}
                </div>
              </GridItem>
              {/* second bart */}

              <GridItem colSpan={1}>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  px={{ base: 0, md: 2, lg: 4 }}
                >
                  <Card width={"100%"} maxWidth={"100%"} boxShadow={"none"}>
                    <CardBody>
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          <Text
                            color="#171717"
                            fontSize={{ base: "10px", md: "10px", lg: "20px" }}
                          >
                            {t("payment_summary")}
                          </Text>
                        </Heading>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                          width={"100%"}
                          mt={{ base: "1.5em", md: "1.5em", lg: "2.5em" }}
                          mb={{ base: "1.5em", md: "1.5em", lg: "2.5em" }}
                        >
                          <Flex flexDirection={"column"}>
                            <Input
                              placeholder={t("coupon_code")}
                              value={
                                cart?.coupon?.code
                                  ? cart?.coupon?.code
                                  : couponCode
                              }
                              size={{ base: "xs", md: "sm", lg: "sm" }}
                              disabled={cart?.coupon?.code ? true : false}
                              onChange={(e) => {
                                setCouponCode(e.target.value);
                              }}
                            />
                          </Flex>
                          <Flex flexDirection={"column"}>
                            <Button
                              bgColor={"white"}
                              color="#171717"
                              size="sm"
                              fontSize={{ base: "7px", md: "7px", lg: "14px" }}
                              marginInlineStart={1}
                              onClick={
                                cart?.coupon?.code
                                  ? () => {
                                      onRemoveCouponCode();
                                      setCouponCode("");
                                    }
                                  : () => onAddCouponCode(couponCode)
                              }
                              isDisabled={
                                !cart.products || cart.products.length === 0
                              }
                            >
                              {cart?.coupon?.code ? "Remove" : t("apply")}
                            </Button>
                          </Flex>
                        </Flex>
                        <Box mb={{ base: "2.5em", md: "2.5em", lg: "5em" }}>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            padding={{ base: 2.5, md: 2.5, lg: 5 }}
                          >
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("subtotal")}
                            </Text>
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {cart?.subtotal}
                            </Text>
                          </Flex>
                          <Divider borderColor={"#171717"} />
                          {cart?.coupon?.code && (
                            <>
                              <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                width={"100%"}
                                padding={{ base: 2.5, md: 2.5, lg: 5 }}
                              >
                                <Text
                                  style={textStyle}
                                  fontSize={{
                                    base: "8px",
                                    md: "8px",
                                    lg: "16px",
                                  }}
                                >
                                  {t("coupon_code")}
                                </Text>
                                <Text
                                  style={textStyle}
                                  fontSize={{
                                    base: "8px",
                                    md: "8px",
                                    lg: "16px",
                                  }}
                                >
                                  {cart?.coupon?.code}
                                </Text>
                              </Flex>
                              <Divider borderColor={"#171717"} />
                            </>
                          )}
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            padding={{ base: 2.5, md: 2.5, lg: 5 }}
                          >
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("deliveryPrice")}
                            </Text>
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {cart?.governor?.price}
                            </Text>
                          </Flex>
                          <Divider borderColor={"#171717"} />
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            padding={{ base: 2.5, md: 2.5, lg: 5 }}
                          >
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {t("totalPrice")}
                            </Text>
                            <Text
                              style={textStyle}
                              fontSize={{ base: "8px", md: "8px", lg: "16px" }}
                            >
                              {cart?.total}
                            </Text>
                          </Flex>
                          <Divider borderColor={"#171717"} />
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            width={"100%"}
                            padding={{ base: 2.5, md: 2.5, lg: 5 }}
                          >
                            <Radio
                              value="cash"
                              onChange={(e) => {
                                handleRadioChange(e);
                                setSelectedPaymentMethod(e.target.value);
                              }}
                              isChecked={selectedPaymentMethod === "cash"}
                              style={
                                selectedPaymentMethod === "cash"
                                  ? {
                                      borderColor: "#171717",
                                      backgroundColor: "#171717",
                                    }
                                  : {}
                              }
                            >
                              <Text
                                style={textStyle}
                                fontSize={{
                                  base: "8px",
                                  md: "8px",
                                  lg: "16px",
                                }}
                              >
                                {t("cash")}
                              </Text>
                            </Radio>
                            <Radio
                              value="master"
                              variant="groove"
                              onChange={(e) => {
                                handleRadioChange(e);
                                setSelectedPaymentMethod(e.target.value);
                              }}
                              isChecked={selectedPaymentMethod === "master"}
                              style={
                                selectedPaymentMethod === "master"
                                  ? {
                                      borderColor: "#171717",
                                      backgroundColor: "#171717",
                                    }
                                  : {}
                              }
                            >
                              <Text
                                style={textStyle}
                                fontSize={{
                                  base: "8px",
                                  md: "8px",
                                  lg: "16px",
                                }}
                              >
                                {t("otherPaymentMethods")}
                              </Text>
                            </Radio>
                          </Flex>
                        </Box>
                      </Stack>
                    </CardBody>
                    {/* <Divider /> */}
                    <CardFooter padding={0}>
                      <Button
                        borderRadius={0}
                        size={{ base: "sm", md: "md", lg: "lg" }}
                        width="100%"
                        variant="ghost"
                        bgColor={"#171717"}
                        color={"white"}
                        padding={{ base: 5, md: 5, lg: 10 }}
                        onClick={onOpen}
                        _hover={{ bgColor: "#171717" }}
                        isDisabled={
                          !cart?.products || cart?.products.length === 0
                        }

                        // colorScheme="#171717"
                      >
                        {t("checkout")}
                      </Button>
                    </CardFooter>
                  </Card>
                </Flex>
              </GridItem>
            </Grid>
          </Box>
        );
      else return emptyCart();
    else return emptyCart();
  else return emptyCart();
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    cart: state.orders.cart,
    spinner: state.user.spinner,
    cartSpinner: state.orders.spinner,
    // profile: state.user.profile,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onGetCart: () => dispatch(getCart()),
  onIncreaseProductCart: (productId: number | undefined) =>
    dispatch(increaseProductCart(productId)),
  onDecreaseProductCart: (productId: number | undefined) =>
    dispatch(decreaseProductCart(productId)),
  onDeleteProductFromCart: (productId: number | undefined) =>
    dispatch(deleteProductFromCart(productId)),
  onAddCouponCode: (code: string) => dispatch(addCouponCode(code)),
  onRemoveCouponCode: () => dispatch(removeCouponCode()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
