// userReducer.ts
export interface MainState {
  spinner: string | null;
  home: any | null;
  videos: any | null;
  notifications: any | null;
  sliders: any | null;
  flashDealSliders: any | null;
  banners: any | null;
  popNotification: any | null;
  pages: any | null;
}

interface Action {
  type: string;
  res?: any;
}

const initState: MainState = {
  spinner: null,
  home: null,
  videos: null,
  notifications: null,
  sliders: null,
  flashDealSliders: null,
  banners: null,
  popNotification: null,
  pages: null,
};

const homeReducer = (
  state: MainState = initState,
  action: Action
): MainState => {
  switch (action.type) {
    case "GET_HOME_SUCCESS":
      return {
        ...state,
        spinner: "success",
        home: action.res.data.data,
      };
    case "GET_HOME_ERROR":
      return {
        ...state,
      };
    case "GET_HOME_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_SLIDERS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        sliders: action.res.data.data,
      };
    case "GET_SLIDERS_ERROR":
      return {
        ...state,
      };
    case "GET_SLIDERS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_VIDEOS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        videos: action.res.data.data,
      };
    case "GET_VIDEOS_ERROR":
      return {
        ...state,
      };
    case "GET_VIDEOS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };

    case "GET_NOTIFICATIONS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        notifications: action.res.data.data,
      };
    case "GET_NOTIFICATIONS_ERROR":
      return {
        ...state,
      };
    case "GET_NOTIFICATIONS_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_POP_NOTIFICATION_SUCCESS":
      return {
        ...state,
        spinner: "success",
        popNotification: action.res.data.data,
      };
    case "GET_POP_NOTIFICATION_ERROR":
      return {
        ...state,
        spinner: "error",
      };
    case "GET_POP_NOTIFICATION_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_PAGES_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_PAGES_SUCCESS":
      return {
        ...state,
        spinner: "success",
        pages: action.res.data.data,
      };
    case "GET_PAGES_ERROR":
      return {
        ...state,
        spinner: "error",
      };
    case "GET_Flashes_SUCCESS":
      return {
        ...state,
        spinner: "success",
        flashDealSliders: action.res.data.data,
      };
    case "GET_Flashes_ERROR":
      return {
        ...state,
      };
    case "GET_Flashes_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    case "GET_Banners_SUCCESS":
      return {
        ...state,
        spinner: "success",
        banners: action.res.data.data,
      };
    case "GET_Banners_ERROR":
      return {
        ...state,
      };
    case "GET_Banners_SPINNER":
      return {
        ...state,
        spinner: "loading",
      };
    default:
      return state;
  }
};

export default homeReducer;
