import { Dispatch } from "redux";
import { OpenAPI } from "../../api/gen/core/OpenAPI";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { ApiRequestOptions } from "../../api/gen/core/ApiRequestOptions";
import { RootState } from "../reducers/rootReducer";
import { useState } from "react";
import { ErrorNotification } from "../../utils/Notifications/notifications";
import { pageEvent } from "../../config/FirebaseConfig";

export interface Action {
  type: string;
  res?: any;
  err?: any;
}
const storedValue = localStorage.getItem("currency");
const language = localStorage.getItem("language");

function objectToFormData(obj: Record<string, any>): FormData {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    formData.append(key, value);
  }

  return formData;
}
export const sendMessage = (
  formData: Record<string, any> // Assuming car is an object, adjust the type accordingly
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "ADD_MESSAGE_LOADING" });
    pageEvent("Messages", "Send Message");
    const myFormData = objectToFormData(formData);
    axios
      .post(`${OpenAPI.BASE}/messages/add`, myFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "My-Custom-Header": "foobar",
          lang: language,
          currency: storedValue,
          "Content-Type": "multipart/form-data",
          // Omitting Content-Type or setting it to 'application/json' if needed
        },
      })
      .then((res) => {
        dispatch({ type: "ADD_MESSAGE_SUCCESS", res });
        dispatch({ type: "GET_MESSAGES_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/messages`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_MESSAGES_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_MESSAGES_ERROR", err });
          });
      })
      .catch((err) => {
        ErrorNotification(err?.response?.data?.errors ?? "Error");
        dispatch({ type: "ADD_MESSAGE_ERROR", err });
      });
  };
};

export const sendOrderMessage = (
  formData: Record<string, any>, // Assuming car is an object, adjust the type accordingly
  orderId: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    dispatch({ type: "ADD_MESSAGE_LOADING" });
    pageEvent("Messages", "Send Order Message");
    const myFormData = objectToFormData(formData);
    axios
      .post(`${OpenAPI.BASE}/drivers/messages/${orderId}/add`, myFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "My-Custom-Header": "foobar",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch({ type: "ADD_MESSAGE_SUCCESS", res });
        dispatch({ type: "GET_ORDER_MESSAGES_SPINNER" });
        axios
          .get(`${OpenAPI.BASE}/drivers/messages/${orderId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              lang: language,
              currency: storedValue,
            },
          })
          .then((res) => {
            dispatch({ type: "GET_ORDER_MESSAGES_SUCCESS", res });
          })
          .catch((err) => {
            dispatch({ type: "GET_ORDER_MESSAGES_ERROR", err });
          });
      })
      .catch((err) => {
        dispatch({ type: "ADD_MESSAGE_ERROR", err });
      });
  };
};

export const createNewConversation = (
  formData: Record<string, any>
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;

    dispatch({ type: "CREATE_NEW_CONVERSATION_LOADING" });
    const emptyFormData = new FormData();
    // const myFormData = objectToFormData(formData);
    axios
      .post(`${OpenAPI.BASE}/messages/add`, emptyFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          // "My-Custom-Header": "foobar",
          lang: language,
          currency: storedValue,
          "Content-Type": "multipart/form-data",
          // Omitting Content-Type or setting it to 'application/json' if needed
        },
      })
      .then((res) => {
        dispatch({ type: "CREATE_NEW_CONVERSATION_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_NEW_CONVERSATION_ERROR", err });
      });
  };
};

export const getConversations = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_CONVERSATIONS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/conversations`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_CONVERSATIONS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_CONVERSATIONS_ERROR", err });
      });
  };
};

export const getParentQuestions = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_QUESTIONS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/chat-questions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_QUESTIONS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_QUESTIONS_ERROR", err });
      });
  };
};

export const getChildQuestions = (
  parentId: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_QUESTIONS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/chat-questions?parent_id=${parentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_QUESTIONS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_QUESTIONS_ERROR", err });
      });
  };
};

export const getConversation = (
  id: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_CONVERSATION_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/conversations/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_CONVERSATION_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_CONVERSATION_ERROR", err });
      });
  };
};

export const getMessages = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_MESSAGES_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/messages`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_MESSAGES_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_MESSAGES_ERROR", err });
      });
  };
};

export const getOrderMessages = (
  id: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_ORDER_MESSAGES_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/drivers/messages/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_ORDER_MESSAGES_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_ORDER_MESSAGES_ERROR", err });
      });
  };
};

export const CheckTicketsStatus = (): ThunkAction<
  void,
  RootState,
  unknown,
  Action
> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    // Assuming you have some way to get the token
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;
    // Replace this with your actual token retrieval
    dispatch({ type: "GET_TICKETS_STATUS_SPINNER" });
    axios
      .get(`${OpenAPI.BASE}/ticket/check`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: language,
          currency: storedValue,
        },
      })
      .then((res) => {
        dispatch({ type: "GET_TICKETS_STATUS_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "GET_TICKETS_STATUS_ERROR", err });
      });
  };
};

export const CreateNewTicket = (
  id: number
): ThunkAction<void, RootState, unknown, Action> => {
  return (dispatch: Dispatch<Action>) => {
    type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;
    const token: string | Resolver<string> | undefined = OpenAPI.TOKEN;

    dispatch({ type: "CREATE_NEW_TICKET_LOADING" });

    axios
      .post(
        `${OpenAPI.BASE}/ticket/create`,
        { chat_question_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "My-Custom-Header": "foobar",
            lang: language,
            currency: storedValue,
            // "Content-Type": "multipart/form-data",
            // Omitting Content-Type or setting it to 'application/json' if needed
          },
        }
      )
      .then((res) => {
        dispatch({ type: "CREATE_NEW_TICKET_SUCCESS", res });
      })
      .catch((err) => {
        dispatch({ type: "CREATE_NEW_TICKET_ERROR", err });
      });
  };
};
