import React from "react";
import { Rating } from "react-simple-star-rating";

interface IProps {
  review: any;
}

const ProductDetailsItemReview = (props: IProps) => {
  return (
    <div dir="ltr" className="flex mt-1 gap-[16px]">
      <img
        className="w-[55px] h-[55px] object-fill rounded-full"
        src={props.review.user.avatar}
        alt=""
      />
      <div className="flex w-[360px] justify-between">
        <div className="flex flex-col gap-[8px]">
          <div className="text-[17px]">{props.review.user.name}</div>
          <div className="text-[15px] text-[#969696]">
            {props.review.user.comment}
          </div>
        </div>

        <div className="flex flex-col gap-[2px] items-center">
          <div className="text-[16px] text-[#969696]">
            {props.review.user.created_at}
          </div>
          <div>
            <Rating
              initialValue={props.review.rating}
              fillColor="#E2963C"
              readonly
              size={22}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsItemReview;
