// Import necessary components and libraries
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";

// Define interface for props
interface IProp {
  image: string;
  typeName: string;
  color: string;
  categoryName: string;
  price: string;
  subPrice: string;
  quantity: number;
  decreaseProductCart: () => void;
  increaseProductCart: () => void;
  deleteProductCart: () => void;
}

// Define styles
const titleStyle = {
  fontSize: "1rem",
  color: "#171717",
};

const descriptionStyle = {
  fontSize: "10px",
};

const subPriceStyle = {
  fontSize: "0.7rem",
  fontWeight: "regular",
};

const priceStyle = {
  fontSize: "0.7rem",
  fontWeight: "regular",
  color: "#DF0000",
};

// Define CheckoutCart component
const CheckoutCart = ({
  categoryName,
  color,
  image,
  price,
  subPrice,
  typeName,
  quantity,
  decreaseProductCart,
  increaseProductCart,
  deleteProductCart,
}: IProp) => {
  // Retrieve stored currency value
  const storedValue = localStorage.getItem("currency");

  // Return JSX
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      maxWidth={"100%"}
      mt={{ base: "1em", md: "1em", lg: "2em" }}
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 2fr 1fr 1fr 1fr" }}
        gap={4}
        width="100%"
      >
        {/* Image */}
        <GridItem>
          <Image
            src={image}
            boxSize="100px"
            maxHeight={"65px"}
            maxWidth={"65px"}
            objectFit="cover"
          />
        </GridItem>

        {/* Text Details */}
        <GridItem>
          <Flex flexDirection={"column"}>
            <Text style={titleStyle} noOfLines={1}>
              {typeName}
            </Text>
            <Text
              style={descriptionStyle}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {categoryName}
            </Text>
            <Text
              style={descriptionStyle}
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {color}
            </Text>
          </Flex>
        </GridItem>

        {/* Price and Discounted Price */}
        <GridItem>
          <Flex flexDirection={"column"} alignItems="flex-start">
            <Text style={subPriceStyle}>
              {storedValue === "USD" ? "$" : "IQD"} {subPrice}
            </Text>
            {price !== "0" && (
              <Text style={priceStyle} mt="0.5em">
                {storedValue === "USD" ? "$" : "IQD"} {price}
              </Text>
            )}
          </Flex>
        </GridItem>

        {/* Quantity Controls */}
        <GridItem>
          <Flex alignItems="center">
            <IconButton
              icon={<FaMinus />}
              aria-label="Decrease Quantity"
              onClick={decreaseProductCart}
              size={{ base: "xs", md: "xs", lg: "sm" }}
              variant="outline"
              color="white"
              bgColor="#969696"
              _hover={{ bgColor: "#969696", color: "#FFFFFF" }}
            />
            <Flex justifyContent={"center"} alignItems={"center"} mx="1em">
              <Text fontSize={"12px"}>{quantity}</Text>
            </Flex>
            <IconButton
              icon={<FaPlus />}
              aria-label="Increase Quantity"
              onClick={increaseProductCart}
              size={{ base: "xs", md: "xs", lg: "sm" }}
              variant="outline"
              color="white"
              bgColor="#969696"
              _hover={{ bgColor: "#969696", color: "#FFFFFF" }}
            />
          </Flex>
        </GridItem>

        {/* Delete Button */}
        <GridItem>
          <IconButton
            icon={<img src="/images/shoppingCart/x.png" alt="" />}
            aria-label="Delete Product"
            size="sm"
            color="#969696"
            bgColor="white"
            onClick={deleteProductCart}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default CheckoutCart;
