import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store/reducers/rootReducer";
import { Action, searchProducts } from "../../store/actions/productActions";
import { connect } from "react-redux";

interface Product {
  id: number;
  name: string;
  thumbnail: string;
  price: string;
  currency: string;
}

interface DispatchProps {
  onSearchProducts: any;
}

interface StateProps {
  spinner: boolean;
  products: Product[];
  totalPages: number;
}

interface Props {
  setSelectedProduct: any;
}

const SearchAutoComplete = (props: DispatchProps & StateProps & Props) => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    from_price: 0,
    to_price: 0,
    search: "",
    category_id: undefined,
    per_page: undefined,
    subCategory_id: undefined,
  });

  const [showSuggestions, setShowSuggestions] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    fieldName: string
  ) => {
    const value = e.target.value === "" ? "" : e.target.value;
    setParams((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));

    if (fieldName === "search" && value) {
      try {
        props.onSearchProducts({ search: value });
        setShowSuggestions(true); // Show suggestions when input changes
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }
  };

  // Hide suggestions on click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false); // Hide suggestions
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box position="relative" maxW="100%" mt="0em" ref={containerRef}>
      <InputGroup>
        <Input
          placeholder={t("search")}
          borderRadius="10px"
          value={params.search || ""}
          onChange={(e) => handleInputChange(e, "search")}
          background={"#24272C"}
          color={"#717377"}
          border={"none"}
          _focus={{ boxShadow: "none", border: "none" }}
          size={{ sm: "sm", xl: "md" }}
        />
        <InputRightElement>
          <IconButton
            aria-label="Search"
            icon={<SearchIcon />}
            bg="transparent"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            color="#FFFFFF"
          />
        </InputRightElement>
      </InputGroup>

      {showSuggestions && props?.products?.length > 0 && params.search && (
        <Box
          position="absolute"
          bg="white"
          w="100%"
          borderRadius="8px"
          boxShadow="lg"
          mt="2"
          zIndex="5"
        >
          <List>
            {props.products.map((product) => (
              <ListItem
                key={product.id}
                p="2"
                cursor="pointer"
                _hover={{ backgroundColor: "#f2f2f2" }}
                onClick={() => {
                  props.setSelectedProduct(product.id);
                  setShowSuggestions(false); // Hide suggestions on selection
                }}
              >
                <Box display="flex" alignItems="center">
                  <Image
                    src={product.thumbnail}
                    alt={product.name}
                    boxSize="40px"
                    mr="10px"
                    borderRadius="5px"
                  />
                  <Box>
                    <Text fontWeight="bold">{product.name}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {product.price} {product.currency}
                    </Text>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    spinner: state.products.spinner,
    products: state.products.searchProducts,
    totalPages: state.products.totalPages,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, unknown, Action>
): DispatchProps => ({
  onSearchProducts: (params: any) => dispatch(searchProducts(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAutoComplete);
