// userReducer.ts
export interface MainState {
  spinner: string | null;
  conversations: any | null;
  conversation: any | null;
  messages: any | null;
  questions: any | null;
  createConversation: boolean;
  ticketsStatus: any | null;
}

interface Action {
  type: string;
  res?: any;
}

const initState: MainState = {
  spinner: null,
  conversations: null,
  conversation: null,
  messages: null,
  questions: null,
  createConversation: false,
  ticketsStatus: null,
};

const messageReducer = (
  state: MainState = initState,
  action: Action
): MainState => {
  switch (action.type) {
    case "GET_CONVERSATIONS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        conversations: action.res.data.data,
      };
    case "GET_CONVERSATIONS_ERROR":
      return {
        ...state,
      };
    case "GET_CONVERSATIONS_SPINNER":
      return {
        ...state,
        spinner: "loading",
        conversations: null,
      };

    case "GET_CONVERSATION_SUCCESS":
      return {
        ...state,
        spinner: "success",
        conversation: action.res.data.data,
      };
    case "GET_CONVERSATION_ERROR":
      return {
        ...state,
      };
    case "GET_CONVERSATION_SPINNER":
      return {
        ...state,
        spinner: "loading",
        conversation: null,
      };

    case "GET_QUESTIONS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        questions: action.res.data.data,
      };
    case "GET_QUESTIONS_ERROR":
      return {
        ...state,
      };
    case "GET_QUESTIONS_SPINNER":
      return {
        ...state,
        spinner: "loading",
        questions: null,
      };

    case "GET_MESSAGES_SUCCESS":
      return {
        ...state,
        spinner: "success",
        messages: action.res.data.data,
      };
    case "GET_MESSAGES_ERROR":
      return {
        ...state,
      };
    case "GET_MESSAGES_SPINNER":
      return {
        ...state,
        spinner: "loading",
        messages: null,
      };

    case "GET_ORDER_MESSAGES_SUCCESS":
      return {
        ...state,
        spinner: "success",
        messages: action.res.data.data,
      };
    case "GET_ORDER_MESSAGES_ERROR":
      return {
        ...state,
      };
    case "GET_ORDER_MESSAGES_SPINNER":
      return {
        ...state,
        spinner: "loading",
        messages: null,
      };

    case "ADD_MESSAGE_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "ADD_MESSAGE_ERROR":
      return {
        ...state,
      };
    case "ADD_MESSAGE_LOADING":
      return {
        ...state,
        spinner: "loading",
      };

    case "CREATE_NEW_CONVERSATION_SUCCESS":
      return {
        ...state,
        createConversation: true,
      };
    case "CREATE_NEW_CONVERSATION_ERROR":
      return {
        ...state,
        createConversation: false,
      };
    case "CREATE_NEW_CONVERSATION_LOADING":
      return {
        ...state,
        spinner: "loading",
        createConversation: false,
      };
    case "CREATE_NEW_TICKET_LOADING":
      return {
        ...state,
        spinner: "loading",
      };
    case "CREATE_NEW_TICKET_SUCCESS":
      return {
        ...state,
        spinner: "success",
      };
    case "CREATE_NEW_TICKET_ERROR":
      return {
        ...state,
        spinner: "error",
      };
    case "GET_TICKETS_STATUS_SPINNER":
      return {
        ...state,
        spinner: "loading",
        ticketsStatus: null,
      };

    case "GET_TICKETS_STATUS_SUCCESS":
      return {
        ...state,
        spinner: "success",
        ticketsStatus: action.res.data.data,
      };
    case "GET_TICKETS_STATUS_ERROR":
      return {
        ...state,
        spinner: "error",
        ticketsStatus: null,
      };
    default:
      return state;
  }
};

export default messageReducer;
