import { IInternalState, AuthLoading } from "./context";
import toggleLoading from "../../utils/helpers/xor";

import {
  ICategories,
  ICategory,
} from "../../api/gen/models/CategoriesServices/listModels";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "SET_LIST"; payload: { data: ICategories } }
  | { type: "SET_SUB_CATEGORIES"; payload: { data: ICategories } }
  | { type: "SET_CATEGORY_DETAILS"; payload: { data: ICategory } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_LIST":
      return {
        ...state,
        list: action.payload.data,
        // subCategoriesList: action.payload.data,
      };
    case "SET_SUB_CATEGORIES":
      return { ...state, subCategoriesList: action.payload.data };
    case "SET_CATEGORY_DETAILS":
      return { ...state, categoryDetails: action.payload.data };

    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };

    default:
      return state;
  }
};

export default reducer;
