import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import error from "../svg/error.svg";
import { useNavigate } from "react-router-dom";

interface IProps {
  status: string;
  isError?: boolean;
}
const RequestIsDone = ({ status, isError }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box width={"100%"}>
      <Grid templateColumns="1fr">
        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          {status == "error" || isError ? (
            <img src={error} style={{ width: "100%", height: "20em" }} alt="" />
          ) : status == "success" ? (
            <img
              src="/images/shoppingCart/success_order.png"
              style={{ width: "20em", height: "20em" }}
              alt=""
            />
          ) : null}
        </Flex>
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          {status == "error" ? (
            <Text
              style={{ fontWeight: "normal" }}
              fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            >
              {t("payment_failed_please_try_again")}
            </Text>
          ) : isError ? (
            <Text
              style={{ fontWeight: "normal" }}
              fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            >
              {t("completing_order_failed_please_contact_support")}
            </Text>
          ) : status == "success" ? (
            <Text
              style={{ fontWeight: "normal" }}
              fontSize={{ base: "20px", md: "20px", lg: "40px" }}
            >
              {t("payment_done_successfully")}
            </Text>
          ) : null}
        </Flex>
        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <a href="/">
            <Button
              backgroundColor={"black"}
              color={"white"}
              _hover={{ bgColor: "#333131" }}
              borderRadius={"0px"}
              width={"200px"}
              className="mt-[30px] "
              onClick={() => navigate("/")}
            >
              {t("done")}
            </Button>
          </a>
        </Flex>
      </Grid>
    </Box>
  );
};

export default RequestIsDone;
